import { Disclosure } from '@headlessui/react';
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline';



export default function FAQs() {
    const faqs = [
        {
            question: "Is Fire AI app generator available without a subscription?",
            answer:
                "Yes, you can create up to 3 apps for free using our trial version. Please note that the free trial version only includes basic functions and does not provide access to the features available in the pro version.",
        },
        {
            question: "What is the refund policy?",
            answer:
                "If you are not satisfied with Fire AI, you can request a refund within 7 days of the start of your subscription. You will receive a refund without any questions asked. Refund requests are processed within 48 hours. After 7 days, you can cancel your subscription at any time, but we don’t do pro rata refunds and you will have access to the Pro features until the end of your billing cycle.",
        },
        {
            question: "What is the Account Deletion Policy?",
            answer:
                "To delete your account and all associated data, send an email to the Fire AI team with your account details and a request for account deletion. The team will process your request within 48 hours. After this period, all data associated with your account, including your apps and app history, will be permanently deleted.",
        }

    ]

    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
                <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                    <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
                    <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                        {faqs.map((faq) => (
                            <Disclosure as="div" key={faq.question} className="pt-6">
                                {({ open }) => (
                                    <>
                                        <dt>
                                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                                <span className="text-base font-semibold leading-7">{faq.question}</span>
                                                <span className="ml-6 flex h-7 items-center">
                                                    {open ? (
                                                        <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    ) : (
                                                        <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    )}
                                                </span>
                                            </Disclosure.Button>
                                        </dt>
                                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                            <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </dl>
                    <br></br>
                    <h2 className="text-base leading-7 tracking-tight text-gray-600">Can't find what you want to know? Send us an email at  <a href="mailto: support@fireaiapp.com" className='text-[#ea5252dd]'>support@fireaiapp.com</a>.</h2>
                </div>
            </div>
        </div>
    )
}