import { StarIcon } from '@heroicons/react/20/solid'


export default function Pricing() {
    const tiers = [
        {
            name: 'Free Trial',
            id: 'tier-basic',
            href: 'https://dev.fireaiapp.com/sign-up',
            price: null,
            description: "If you're unsure about it, give it a try! It won't cost you a penny!",
            features: ['Early birds can create 3 apps for free.',
                "Users register later than 12 am GMT, 30 Oct 2023 can create 1 app for free.",
                "Each app has 3 generation attempts",
                "1 month unlimited visitors's access to your apps"],
        },
        {
            name: 'Subscription',
            id: 'tier-essential',
            href: 'https://dev.fireaiapp.com/pricing',
            price: { monthly: '$29', annually: '$240' },
            description: 'If you like it and want to explore more, just subscribe and become a member of our FIRE community!',
            features: [
                'Unlimited App Creation',
                'Unlimited App Update',
                "Unlimited Visitors to your apps",
                'Setup custom domain[Beta]',
                'Database Integration[Beta]',
                'Market Place Publishing[Beta]',
                'Auto Test App Function[Beta]',
                'Payment Integration Function[Beta]'
            ],
        },
        {
            name: 'Once for all',
            id: 'tier-growth',
            href: 'https://dev.fireaiapp.com/pricing',
            price: { monthly: null, annually: null, oneoffpayment: '$999' },
            description: "If you're interested in how FIREAI works or want to build something similar, give this plan a try.",
            features: [
                'You will be authorised to white label FIREAI.',
                '14 days free customer support ($499 for another 12 months support available)',
                'Discounted price when software updates available',
                'Contact us at support@fireaiapp.com for self-hosting option',
            ],
        },
    ]

    return (
        <div className="bg-white py-16 sm:py-24" id="plans">
            
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-4xl sm:text-center">
                    <h2 className="text-base font-semibold leading-7 text-[#ea5252] opacity-90">Pricing</h2>
                    <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                        Choose the right plan for you
                    </p>
                </div>

                <p className="mx-auto mt-6 max-w-2xl text-lg leading-8 text-gray-600 sm:text-center">
                    This is an early-bird plan with a 7-day refund policy. If you have any questions, please feel free to send us an email at support@fireaiapp.com.                </p>

                <div className="mt-20 flow-root">
                    <div className="isolate -mt-16 grid max-w-sm grid-cols-1 gap-y-16 divide-y divide-gray-300 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:grid-cols-3 lg:divide-x lg:divide-y-0 xl:-mx-4">
                        {tiers.map((tier) => (
                            <div key={tier.id} className="pt-16 lg:px-8 lg:pt-0 xl:px-14">

                                <h3 id={tier.id} className="text-base font-semibold leading-7 text-gray-900">
                                    {tier.name}
                                </h3>

                                {tier.price?.monthly &&
                                    <p className="mt-6 flex items-baseline gap-x-1">
                                        <span className="text-5xl font-bold tracking-tight text-gray-900">{tier.price.monthly}</span>
                                        <span className="text-sm font-semibold leading-6 text-gray-600">/month</span>
                                    </p>}
                                {!tier.price &&
                                    <p className="mt-6 flex items-baseline gap-x-1">
                                        <span className="text-5xl font-bold tracking-tight text-gray-900">$0</span>
                                        <span className="text-sm font-semibold leading-6 text-gray-600"></span>
                                    </p>}

                                {tier.price?.annually &&
                                    <p className="mt-3 text-sm leading-6 text-gray-500">{tier.price.annually} /year if paid annually</p>
                                }
                                {!tier.price &&
                                    <p className="mt-3 text-sm leading-6 text-gray-500">This is free trial and it's for free!!</p>
                                }

                                {tier.price?.oneoffpayment &&
                                    <div>
                                        <p className="mt-6 flex items-baseline gap-x-1">
                                            <span className="text-5xl font-bold tracking-tight text-gray-900">{tier.price.oneoffpayment}</span>
                                            <span className="text-sm font-semibold leading-6 text-gray-600"></span>

                                        </p>
                                        <p className="mt-3 text-sm leading-6 text-gray-500">Build your own business in just {tier.price.oneoffpayment}!</p>
                                    </div>
                                }

                                <a
                                    href={tier.href}
                                    target="_parent"
                                    aria-describedby={tier.id}
                                    className="mt-10 block rounded-md bg-[#ea5252] opacity-90 px-3 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#ea5252dd] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#ea5252]"
                                >
                                    Try this plan
                                </a>

                                <p className="mt-10 text-sm font-semibold leading-6 text-gray-900">{tier.description}</p>
                                <ul role="list" className="mt-6 space-y-3 text-sm leading-6 ">
                                    {tier.features.map((feature) => (
                                        <li key={feature} className="flex gap-x-3">
                                            <StarIcon className="h-6 w-5 flex-none text-[#ea5252bb]" aria-hidden="true" />
                                            {feature}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}