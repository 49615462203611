import { SparklesIcon } from '@heroicons/react/20/solid'


export default function Features() {
    const features = [
        {
            name: 'Fire AI is a TEXT To APP platform',
            description: 'This allows you  to create web apps by providing natural language or text-based input, automating the app development process without traditional coding skills.'
        },
        {
            name: 'Fire AI is ntegrated with AI Chat',
            description: 'This platform will empower you to seamlessly integrate AI chat assistants anywhere within your app, providing enhanced user engagement and support capabilities.',
        },
        {
            name: 'You can effortlessly embed Fire AI apps into your website',
            description: "If you already have a website but wish to have a custom application that suits your needs, there's no need to worry. Just share your requirements with Fire AI, and you will have it in a few seconds.",
        },
        {
            name: 'You can easily share the app you created.',
            description: "Create an app that you're eager to share with your friends? Simply copy the web link and paste it into your chatbox to send it to your friend!",
        },
        {
            name: 'Become a creator with Fire AI(beta)',
            description: 'You can publish and even sell your apps in our app store or elsewhere.',
        },
        {
            name: 'With our help, you can build something similar to Fire AI.',
            description: "By purchasing our 'Once for All' plan, you will have the opportunity to learn how Fire AI works."
        },


    ]

    return (

        <div className="bg-white py-8 sm:py-16" id='features'>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-[#ea5252] opacity-90">FIRE AI</h2>
                        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">What is it?</p>
                        <p className="mt-6 text-base leading-7 text-gray-600">
                            Learn about what you can do with FIRE AI.
                        </p>
                    </div>
                    <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
                        {features.map((feature) => (
                            <div key={feature.name} className="relative pl-9">
                                <dt className="font-semibold text-gray-900">
                                    <SparklesIcon className="absolute left-0 top-1 h-5 w-5 text-[#ea5252dd]" aria-hidden="true" />
                                    {feature.name}
                                </dt>
                                <dd className="mt-2">{feature.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}