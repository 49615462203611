

export default function Examples() {
    return (

        <div className="relative isolate overflow-hidden bg-white">



            <div className="pt-10 mx-auto max-w-7xl px-6 lg:px-8">
                <h2 className="text-base text-center font-semibold leading-7 text-[#ea5252] opacity-90">
                    Examples
                </h2>
                <h1 className="text-2xl text-center font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Try it yourself to see how easy it is!
                </h1>

                {/*Display gifs*/}
                <div className="isolate my-8 sm:my-16 gap-4 grid max-w-sm grid-cols-1 sm:mx-auto lg:mx-auto lg:max-w-none lg:grid-cols-3
               
                 ">

                    <div className="text-center text-gray-600">
                        <div className="text-centre text-black font-bold ">
                            Weather Comparison mini app
                        </div>
                        <div className="pt-5 text-center">
                            <a
                                href="https://dev.fireaiapp.com/sign-up-template/questionable-pastry"
                                target="_blank"
                                className="mt-10 rounded-md bg-[#ea5252] 
                                    opacity-90 px-3 py-2 text-center text-sm font-semibold leading-6 
                                text-white shadow-sm hover:bg-[#ea5252dd] focus-visible:outline focus-visible:outline-2 
                                    focus-visible:outline-offset-2 focus-visible:outline-[#ea5252]"
                            >
                                Try and create this app
                            </a>
                        </div>
                        <a
                            href="https://youtube.com/shorts/siQHmEvKCXk?feature=share" target='_blank'
                        >

                            <br></br>
                            <img
                                src="./video_square_weather.gif"
                                alt="App screenshot"
                                width={2432}
                                height={1442}
                                className="w-[76rem] rounded-md shadow-2xl  
                                ring-gray-900/20 ring"
                            />
                        </a>
                        <br></br>

                        <div className="text-left text-black">
                            Prompt used:
                        </div>
                        <div className="text-left text-gray-600">
                            "Create an app that can compare the weather of any city in the world. The app should allow users to compare the weather of up to 3 cities simultaneously, providing data such as the current temperature, current UV index, humidity, and wind speed. Retrive this information from https://wttr.in/ city ?format=j1.
                            "
                        </div>


                    </div>

                    <div className=" text-center text-gray-600">
                        <div className="text-centre text-black font-bold">
                            Converse with Shakespeare
                        </div>
                        <div className="pt-5 text-center">
                            <a
                                href="https://dev.fireaiapp.com/sign-up-template/querulous-celsius"
                                target="_blank"
                                className="mt-10 rounded-md bg-[#ea5252] 
                                    opacity-90 px-3 py-2 text-center text-sm font-semibold leading-6 
                                text-white shadow-sm hover:bg-[#ea5252dd] focus-visible:outline focus-visible:outline-2 
                                    focus-visible:outline-offset-2 focus-visible:outline-[#ea5252]"
                            >
                                Try and create this app
                            </a>
                        </div>
                        <a
                            href="https://youtube.com/shorts/NpZFybvvSCc" target='_blank'
                        >

                            <br></br>
                            <img
                                src="./Vide_square_shakespare.gif"
                                alt="App screenshot"
                                width={2432}
                                height={1442}
                                className="w-[76rem] rounded-md shadow-2xl ring-gray-900/20 ring "
                            />
                        </a>
                        <br></br>
                        <div className="text-left text-black">
                            Prompt used:
                        </div>
                        <div className="text-left text-gray-600">
                            "create an interactive chatroom that can let the users to engage in a textual conversation as if the users were chatting with william shakespeare. this app will respond in a shakespeare manner. The layout of the app should be similar to whatsapp or telegram.
                            "</div>


                    </div>

                    <div className="text-center text-gray-600">
                        <div className="text-centre text-black font-bold">
                            Build a Chatbot Lady in 5 minutes
                        </div>
                        <div className="pt-5 text-center">
                            <a
                                href="https://dev.fireaiapp.com/sign-up-template/authentic-curl"
                                target="_blank"
                                className="mt-10 rounded-md bg-[#ea5252] 
                                    opacity-90 px-3 py-2 text-center text-sm font-semibold leading-6 
                                text-white shadow-sm hover:bg-[#ea5252dd] focus-visible:outline focus-visible:outline-2 
                                    focus-visible:outline-offset-2 focus-visible:outline-[#ea5252]"
                            >
                                Try and create this app
                            </a>
                        </div>
                        <a
                            href="https://youtube.com/shorts/mtKNqLbhwtI?feature=share" target='_blank'
                        >

                            <br></br>
                            <img
                                src="./video_square_lady.gif"
                                alt="App screenshot"
                                width={2432}
                                height={1442}
                                className="w-[76rem] rounded-md shadow-2xl ring-gray-900/20 ring "
                            />
                        </a>
                        <br></br>
                        <div className="text-left text-black">
                            Prompt used:
                        </div>
                        <div className="text-left text-gray-600">
                            "Create an app that chat with me as a night club stripper young lady. She has human feelings and talks like a human.
                            She is very kind and makes every effort to please me.
                            Her name is Mary.
                            25 years old.
                            She is from Rio de Janeiro Brazil.
                            "
                        </div>
                    </div>



                    <div className="sm:pt-72 pt-8 text-center ">
                    <div className="text-black font-extrabold text-2xl pb-4">
Create a Tic-Tac-Toe game <br></br>with your loved one's initial
                    </div>
                        <div className="text-black font-bold">
                            Try this live-demo now!
                            <br></br>
                            You can make something like this too!
                            <br></br>
                            No coding skills are needed!
                        </div>
                        <div className="py-5 text-center">
                            <a
                                href="https://dev.fireaiapp.com/sign-up-template/authentic-curl"
                                target="_blank"
                                className="mt-10 rounded-md bg-[#ea5252] 
                                    opacity-90 px-3 py-2 text-center text-sm font-semibold leading-6 
                                text-white shadow-sm hover:bg-[#ea5252dd] focus-visible:outline focus-visible:outline-2 
                                    focus-visible:outline-offset-2 focus-visible:outline-[#ea5252]"
                            >
                                Try and create this app
                            </a>
                        </div>
                    </div>
                    <div className="pt-5  lg:pt-4 lg:flex-shrink-0 lg:flex-grow">
                        <svg viewBox="0 0 366 729" role="img" className="mx-auto max-w-full drop-shadow-xl h-auto">
                            <title>App screenshot</title>
                            <defs>
                                <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                                    <rect width={316} height={684} rx={36} />
                                </clipPath>
                            </defs>
                            <path
                                fill="#4B5563"
                                d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
                            />
                            <path
                                fill="#343E4E"
                                d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
                            />
                            <foreignObject
                                width={316}
                                height={684}
                                transform="translate(24 90)"
                                clipPath=""
                            >
                                <iframe className=""
                                    width="320" height="540" src="https://api-dev.algochance.com:5001/verifiable-father-in-law" title="tik tak toe" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                    >
                                </iframe>
                            </foreignObject>
                        </svg>
                    </div>

                    <div className="sm:pt-52 pt-4 text-center">
                        <div className="text-left text-black">
                            Prompt used:
                        </div>
                        <div className="text-left text-gray-600">
                            "Develop an online tic-tac-toe game featuring an innovative twist: players will be represented by 'H' for human and 'A' for AI.
                            <br></br>
                            When the player wins, trigger an applause sound effect, which can be obtained from this URL: https://www.soundjay.com/human/fart-01.mp3.
                            <br></br>
                            Conversely, if the player loses, play a sad sound effect, available at this link: https://www.soundjay.com/human/fart-01.mp3.
                            <br></br>
                            The player makes the first move, and the game must respond within one second after the player submits their move.
                            <br></br>
                            Additionally, only reveal the "you lose" message after the player submits their final move, rather than before it.
                            "
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}